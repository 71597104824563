import React from 'react'
import Icon1 from '../../../images/svg_1.svg'
import Icon2 from '../../../images/svg_2.svg'
import Icon3 from '../../../images/svg_3.svg'
import Icon4 from '../../../images/svg_4.svg'
import Icon5 from '../../../images/svg_5.svg'
import Icon6 from '../../../images/svg_6.svg'
import Icon7 from '../../../images/svg_7.svg'
import Icon8 from '../../../images/svg_6.svg'
import useTypingEffect from '../TypingEffect/index';
import { useState } from 'react';

import {
    ServicesWrapper,
    ServicesCard,
    ServicesIcon,
    ServicesH2,
    ServicesP
} from './CardSectionElements'

const CardSection = () => {
    const shortDescriptions = [
        '',
        '',
        '',
        '',
        ''
    ];

    const longDescriptions = [
        "World's No. 1 Independent Verifier on EU MRV and IMO DCS Services.",
        "Innovative Digital Dashboard to log in and check project status. ",
        "Specialized team of Subject Matter Experts.",
        "Efficient, experimented, flexible, and competitive Clear feedback on the process and next steps.",
        "Continuous high level of satisfaction among clients.",
        "Web Based Platform with no installation needed.",
        "Dedicated Services with 24*7 support to clients.",
        "Emissions dashboard contracted for 400+ vessels and getting data verified on a weekly basis."
    ];

    const [expandedDescriptions, setExpandedDescriptions] = useState({
        0: false,
        1: false,
        2: false,
        3: false,
        4: false
    });

    const toggleDescription = (index) => {
        setExpandedDescriptions(prevState => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    };

    const buttonStyles = {
        background: 'none',
        color: 'black',
        border: 'none',
        padding: '8px 16px',
        cursor: 'pointer',
    }

    return (
        <>
            <ServicesWrapper>
                <ServicesCard>
                    <ServicesIcon src={Icon1} />
                    <ServicesH2>Independent Verifier</ServicesH2>
                    <ServicesP>{expandedDescriptions[0] ? longDescriptions[0] : shortDescriptions[0]}<br /><button onClick={() => toggleDescription(0)} style={buttonStyles}>{expandedDescriptions[0] ? 'Read Less' : 'Read More'}</button></ServicesP>
                </ServicesCard>
                <ServicesCard>
                    <ServicesIcon src={Icon2} />
                    <ServicesH2>Innovative Dashboard</ServicesH2>
                    <ServicesP>{expandedDescriptions[1] ? longDescriptions[1] : shortDescriptions[1]}<br /><button onClick={() => toggleDescription(1)} style={buttonStyles}>{expandedDescriptions[1] ? 'Read Less' : 'Read More'}</button></ServicesP>
                </ServicesCard>
                <ServicesCard>
                    <ServicesIcon src={Icon3} />
                    <ServicesH2>Subject Matter Experts</ServicesH2>
                    <ServicesP>{expandedDescriptions[2] ? longDescriptions[2] : shortDescriptions[2]}<br /><button onClick={() => toggleDescription(2)} style={buttonStyles}>{expandedDescriptions[2] ? 'Read Less' : 'Read More'}</button></ServicesP>
                </ServicesCard>
                <ServicesCard>
                    <ServicesIcon src={Icon4} />
                    <ServicesH2>Key Qualities</ServicesH2>
                    <ServicesP>{expandedDescriptions[3] ? longDescriptions[3] : shortDescriptions[3]}<br /><button onClick={() => toggleDescription(3)} style={buttonStyles}>{expandedDescriptions[3] ? 'Read Less' : 'Read More'}</button></ServicesP>
                </ServicesCard>
                <ServicesCard>
                    <ServicesIcon src={Icon5} />
                    <ServicesH2>Client Satisfaction</ServicesH2>
                    <ServicesP>{expandedDescriptions[4] ? longDescriptions[4] : shortDescriptions[4]}<br /><button onClick={() => toggleDescription(4)} style={buttonStyles}>{expandedDescriptions[4] ? 'Read Less' : 'Read More'}</button></ServicesP>
                </ServicesCard>
                <ServicesCard>
                    <ServicesIcon src={Icon6} />
                    <ServicesH2>Web Based Platform</ServicesH2>
                    <ServicesP>{expandedDescriptions[5] ? longDescriptions[5] : shortDescriptions[5]}<br /><button onClick={() => toggleDescription(5)} style={buttonStyles}>{expandedDescriptions[5] ? 'Read Less' : 'Read More'}</button></ServicesP>
                </ServicesCard>
                <ServicesCard>
                    <ServicesIcon src={Icon7} />
                    <ServicesH2>24x7 Support</ServicesH2>
                    <ServicesP>{expandedDescriptions[6] ? longDescriptions[6] : shortDescriptions[6]}<br /><button onClick={() => toggleDescription(6)} style={buttonStyles}>{expandedDescriptions[6] ? 'Read Less' : 'Read More'}</button></ServicesP>
                </ServicesCard>
                <ServicesCard>
                    <ServicesIcon src={Icon8} />
                    <ServicesH2>Emissions Monitoring</ServicesH2>
                    <ServicesP>{expandedDescriptions[7] ? longDescriptions[7] : shortDescriptions[7]}<br /><button onClick={() => toggleDescription(7)} style={buttonStyles}>{expandedDescriptions[7] ? 'Read Less' : 'Read More'}</button></ServicesP>
                </ServicesCard>
            </ServicesWrapper>
        </>
    )
}

export default CardSection

import React from 'react';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages';
import RequestDemo from './components/RequestDemo';
import PrivacyPolicy from './components/PrivacyPolicy';
import './App.css'
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/demo" element={<RequestDemo />} />
        <Route path="/privacy_policy" element={<PrivacyPolicy />} />
      </Routes>
    </Router>
  );
}

export default App;

import React, { useState } from 'react';
import axios from 'axios';
import {
  FormWrap,
  ContentContainer,
  Container,
  Icon,
  FormH1,
  Text,
  TextHeading,
  Table,
  TableHeader,
  TableData
} from './PrivacyPolicyElements';
import { FaChevronCircleLeft } from 'react-icons/fa';

const PrivacyPolicy = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    mobile: '',
    email: '',
    company: '',
    country: '',
    message: '',
  });
  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    axios
      .post('/submit-form', formData, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        console.log(response.data);
        setLoading(false);
        setEmailSent(true);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  return (
    <>
      <Container>
        <FormWrap>
          <Icon to='/'>
            <FaChevronCircleLeft />
          </Icon>
          <ContentContainer>
            <FormH1>Privacy Policy</FormH1>
            <TextHeading>
              VTS Services Privacy Policy
            </TextHeading>
            <Text>
              We value your privacy and respect your interest in knowing how information about you is collected and used. This Policy covers the Privacy practices that VTS, a company incorporated under the laws of India, having its registered office at Normec Verifavia-IHM Technical, Office No 4, Third Floor, Tower C, DLF Building, IT Park, Chandigarh (160101), India which offers products for purchase, or your purchase of products ("Services") available on Web applications ("Platform") and its subsidiaries and affiliates ("VTS" or "We") employ when providing services and support to its customers. This privacy policy (“Policy”) describes how we collect, use, and disclose information that we gather about visitors to our websites, including Platform and the VTS mobile applications (collectively, “Apps”), as well as our Services, and the information we collect when we communicate with customers, users, or other individuals related to the Services (whether by phone, email, or other method). This Policy also outlines your rights and choices with regard to the information collected about you.
            </Text>
            <Text>
              VTS provides variety of Services, which allow users to create communication between buyer and seller about anything in different pricing formats and locations. The actual contract for sale is directly between sellers and buyers. While we may provide history details of vendor about variety of Pricing Format, Quality and Location Format, such details are solely informational and you may decide to use it or not. VTS has no control over and does not guarantee the existence, quality, safety or legality of items provided by vendor, the truth or accuracy of users’ content or listings, the ability of sellers to sell items, the ability of buyers to pay for items, or that a buyer or seller will actually complete a transaction or return an item.
            </Text>
            <Text>
              This Policy sets out how VTS handles your personal data. VTS is the data controller for your personal data. Where processing of personal data is undertaken by our affiliated companies, they are joint controllers with VTS for your personal data. Our current affiliate controller is: VTS , Normec Verifavia-IHM Technical, Office No 4, Third Floor, Tower C, DLF Building, IT Park, Chandigarh (160101), India. Correspondence can be directed to VTS Headquarter contact.verifavia@normecgroup.com.
            </Text>



            <TextHeading>
              Application of Policy
            </TextHeading>
            <Text>
              This Policy applies to any visitor to our Platform; users of the Services; individuals who contact us or with whom we communicated via phone, email, or otherwise; and Customers, including both free trial and paid account holders.
            </Text>
            <TextHeading>
              1. Content
            </TextHeading>
            <Text>
              Our Services permit Customers to share and manage information by creating, uploading, and attaching what we call “Content” to in our software/application that can be shared, stored, and accessed through the Platform. In this Policy, we distinguish between Content and all other information about you. We have no control over the information contained within Content, including any personal data. Content does not include usage information we collect about how users access, create, share, and manage Content (e.g., file sizes or access logs). VTS is a data processor of the Content, and will only process personal data from the Content on behalf, and under the instructions, of our Customers (the data controllers) or where otherwise required by applicable laws. Please refer to the Terms of Service Agreement that governs your use of the Services.
            </Text>
            <Text>
              Sensitive Data. We have no control over whether Sensitive Data is contained in Content. However, we do not intentionally collect - and will not request - Sensitive Data. If a VTS employee discovers that we have received Sensitive Data, the employee will inform a designated contact within our company who will assess the processing of such data. “Sensitive Data” means personal data that discloses an individual’s racial or ethnic origin, political opinions, religious or philosophical beliefs, trade-union membership, criminal proceedings, biometrics, and data concerning health.
            </Text>


            <TextHeading>
              2. Other Information We Collect About You
            </TextHeading>
            <Text>
              This section describes information that is not Content.
            </Text>
            <Text>
              Information We May Collect Directly from You.
            </Text>
            <Text>
              <b>At Account Creation:</b> An email address is required to provision a new account (paid or trial) to use our Platform. Without this, we are unable to create your account. You may choose to provide other information at account creation, including contact information (full name, phone number, etc.), employment details (company name/size, your job title, etc.), and a system administrator’s email (if not you).
            </Text>
            <Text>
              <b>When Enhancing Your Profile:</b> Account-related information described above can be added in the Platform to enhance your profile. You may also choose to add a profile image.
            </Text>
            <Text>
              <b>When Purchasing Services:</b> If you purchase a paid subscription, you may need to provide us billing and payment information, including full name, company name, billing/shipping address, and credit card number. If you do not provide us this information, you may be unable to subscribe and your access to our Services may be limited.
            </Text>
            <Text>
              <b>When Attending Events:</b> We may collect or otherwise receive personal data such as your name, address, phone number, and email when you register for or attend an event where VTS in a sponsor or participant.
            </Text>
            <Text>
              <b>In Online Submissions:</b> We collect information through interactive features of our Platform - e.g., when you submit online forms; participate in surveys, contests, promotions, or sweepstakes; join online chat discussions; request customer support; respond to “Contact Us” invitations; submit testimonials; or if you refer a friend (see Referrals). Personal data gathered may include contact information (full name, phone number, email, etc.), employment details (company name/size, job title, etc.), information about your use of VTS, and any other information you choose to share.
            </Text>
            <Text>
              <b>In Other Communications:</b> You may share information in communications with us relating to the Services, including during phone calls (and call recordings), chats, or over email. Personal data gathered may include contact information, employment details, user preferences, and any other information you choose to share. Please only provide us personal data that we need in order to respond to your request.
            </Text>
            <Text>
              <i>Information We Collect From our Clients.</i> If you use the Services through our Client account, our client will provide VTS your email address in order to provision your account. Our Client may choose to share additional information about you including your profile image, contact information (e.g., full name, phone number, etc.), and employment details (e.g., job title).
            </Text>
            <Text>
              <i>Information We Collect From Third Parties.</i>
            </Text>
            <Text>
              <b>When Purchasing Services:</b> A third-party intermediary is used to manage credit card processing. It is not permitted to store, retain, or use your billing information for any purpose except for credit card processing on our behalf.
            </Text>
            <Text>
              <b>Third Party Sources:</b> Subject to applicable laws, we may gather information about you from lead-sharing tools including LinkedIn Lead Generation, or as leads from VTS’s global resale partners, as well as public information - including internet searches relating to you or your company - in order to better service your account and to provide more relevant assistance and marketing.
            </Text>
            <Text>
              <i>Information We Collect Automatically.</i> We gather information about your use of the Services through cookies, web beacons, java script, log files, database lookup, pixels, and other technologies. We may automatically collect: your domain name, browser type, browser language preference, device type and operating system; page views and links you click within the Platform; IP address, device ID, or other identifier; location information; date and time stamp, and time spent using the Services; referring URL; and your activity within the Platform. We may combine this information with other information we have collected about you, including your user name, name, and other personal data. See “Use of Cookies, Tracking Technologies, Analytics and Behavioral Marketing” for details.
            </Text>

            <TextHeading>
              3. Content
            </TextHeading>
            <Text>
              Our Customers are responsible for ensuring that Content is collected and handled (including any personal data therein) in compliance with applicable laws. As a processor, we handle Content as directed by our Customers (the controllers), pursuant to our relevant customer agreements. We only access Content as necessary to:
            </Text>
            <Text>
              Respond to customer support requests.
            </Text>
            <Text>
              Comply with the law or legal proceedings; for example, we may disclose Content in response to lawful requests by public authorities, including responding to national security or law enforcement disclosure requirements.
            </Text>
            <Text>
              Investigate, prevent, or take action against suspected abuse, fraud, or violation of our policies and terms.
            </Text>

            <TextHeading>
              4. Purposes for which We Use Personal Data
            </TextHeading>
            <Text>
              <b>Provision of Services:</b> To provide and operate our Services, fulfill your orders and requests, process your payments, for bug and error reporting and resolution, to perform upgrades and maintenance, and for similar purposes.
            </Text>
            <Text>
              <b>Customer Support:</b> To communicate with you about your use of the Services; respond to your communications, complaints and inquiries; provide technical support; and for other customer service and support purposes.
            </Text>
            <Text>
              <b>Personalization:</b> To tailor content we send or display to you in order to offer location customization and personalized help and instructions, and to otherwise personalize your experience using the Services.
            </Text>
            <Text>
              <b>Marketing and Promotions:</b> With your permission and/or where permitted by law, we may use contact information for direct marketing and promotional purposes. For example, we may use contact information such as your email address to send you newsletters, special offers or promotions, or to otherwise contact you about VTS products or information we think may interest you. As explained above, we do not use Content for direct marketing purposes. You may opt out of receiving marketing emails by following the opt-out instructions in the email or emailing contact.verifavia@normecgroup.com. We may still email customer service and transaction-related communications, even if you have opted out of receiving marketing communications.
            </Text>
            <Text>
              <b>Advertising:</b> To assist in advertising the Services on third party websites.
            </Text>
            <Text>
              <b>Analytics and Improvement:</b> To better understand how users access and use the Services, and for other research and analytical purposes, such as to evaluate and improve the Services and to develop additional products, services, and features. While we may collect and analyze usage details (e.g., storage size used, access logs, etc.) related to Content, we do not actually access Content for these purposes.
            </Text>
            <Text>
              <b>Protect Legal Rights and Prevent Misuse:</b> To protect the Services; prevent unauthorized access and other misuse; and where we believe necessary to investigate, prevent, or take action regarding illegal activities, suspected fraud, situations involving potential threats to the safety of any person, or violations of our Terms of Service Agreement or this Policy.
            </Text>
            <Text>
              <b>Comply with Legal Obligations:</b> To comply with the law or legal proceedings; for example, we may disclose information in response to lawful requests by public authorities, including responding to national security or law enforcement disclosure requirements.
            </Text>
            <Text>
              <b>General Business Operations:</b> Where necessary to the administration of our general business, accounting, recordkeeping, and legal functions.
            </Text>
            <Table>
              <thead>
                <tr>
                  <TableHeader>Purpose of Processing /Legitimate Business Interests (see above)	</TableHeader>
                  <TableHeader>Legal Bases of Processing (EU Users)*
                  </TableHeader>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <TableData>
                    Provision of Services <br></br><br></br>
                    Customer Support
                  </TableData>
                  <TableData>• Necessary to Enter into or Perform a Contract with You (upon your request, or as necessary to make the Services available)</TableData>
                </tr>
                <tr>
                  <TableData></TableData>
                  <TableData>• Our Legitimate Business Interests*</TableData>
                </tr>
                <tr>
                  <TableData>
                    Personalization <br></br><br></br>
                    Marketing and Promotions <br></br><br></br>
                    Advertising
                  </TableData>
                  <TableData>
                    • Our Legitimate Business Interests** <br></br><br></br>
                    • With Your Consent
                  </TableData>
                </tr>
                <tr>
                  <TableData>
                    Analytics and Improvement
                  </TableData>
                  <TableData>
                    • Our Legitimate Business Interests** <br></br><br></br>
                    • With Your Consent
                  </TableData>
                </tr>
                <tr>
                  <TableData>
                    Protect Rights and Prevent Misuse <br></br><br></br>
                    Comply with Legal Obligation
                  </TableData>
                  <TableData>
                    • Compliance with law <br></br><br></br>
                    • Establish, defend, or protect of legal interests
                  </TableData>
                </tr>
                <tr>
                  <TableData>
                    General Business Operations
                  </TableData>
                  <TableData>
                    • Compliance with law <br></br><br></br>
                    • Establish, defend, or protect of legal interests <br></br><br></br>
                    • Compliance With Law
                  </TableData>
                </tr>
              </tbody>
            </Table>
            <Text>
              *For the personal data from the EU that we process, this column describes the relevant legal bases for such processing under GDPR (and local implementing laws of EU member states); this does not limit or modify the obligations, rights, and requirements under the privacy laws of non-EU jurisdictions.
            </Text>
            <Text>
              ** For the personal data from the EU, the processing is in our legitimate interests, which are not overridden by your interests and fundamental rights. Marketing to EU data subjects is done only with opt-in consent.
            </Text>



            <TextHeading>
              5. How We Share Information
            </TextHeading>
            <Text>
              We will not sell information about you to a third party or allow a third party to use information we provide for its own marketing purposes. We may share information about you with your consent, at your request, or as follows:
            </Text>
            <Text>
              <i>Users</i>
            </Text>
            <Text>
              Content and Usage: VTS is a data processor with respect to Content and certain other user information we collect in providing the Services to our customers. This means: (a) the Customer controls the information and determines how it may be used, and (b) we will process this information only under the written instructions of our Customer or where otherwise required by applicable laws. So, if you use the Services under a Customer account, Content and other information associated with your account (e.g., who has accessed, shared, amended, created, edited, or deleted Content) may be disclosed to the Customer or an administrator on for the corporate Customer account.
            </Text>
            <Text>
              Account Discovery: If the email address which you used to register with us belongs to a entity (with the exception of known service providers such as WSS), we may disclose your email address and account information to (a) users associated with that entity if you are administrator in order to help those users contact you, and (b) the entity and its administrators in order to help them understand who in the organization is using the service.
            </Text>
            <Text>
              <i>To Other Users of the Services</i>
            </Text>
            <Text>
              Content: Content you choose to share with, or make available to, other users is shared as designated by you, and you should consider that it may be further shared by your collaborators; we are not responsible for, nor does this Policy apply to, the collection, use, processing, or sharing of Content by other users in this manner.
            </Text>
            <Text>
              To Payment Processors: If you use a third party to facilitate your payment obligations, we will share certain account-usage and billing-related information about your account with such third party for billing and business administration purposes. Resellers and payment processors are independent data controllers of your personal data.
            </Text>
            <Text>
              When Processing Referrals: When you refer a friend who later signs up for the Services, we may contact you about the successful referral to provide you a referral credit.
            </Text>
            <Text>
              In Testimonials: With your consent, we may publish Customer testimonials you share with us, which could contain personal data such as your full name and other information you choose to share. If you wish to update or remove your testimonial, please notify us at contact.verifavia@normecgroup.com.
            </Text>
            <Text>
              Through Use of Community Features: Our Platform may include interactive features, including forums, online communities, bulletin boards and publicly accessible blogs (“Community Features”). You should be aware that any information that you post in a Community Feature might be read, collected, and used by others who access it. To request removal of your personal data from a Community Feature, contact us at contact.verifavia@normecgroup.com. We will make commercially reasonable efforts to remove your personal data from our Platform, and will let you know if we are unable to do so and why.
            </Text>
            <Text>
              To Our Service Providers: We may share information about you with third party vendors, consultants and other service providers (data processors) who are working on our behalf or providing services to us. We obtain appropriate contractual protections to limit these service providers’ use and disclosure of any information about you that we share with them.
            </Text>
            <Text>
              Infrastructure Processors: We use certain third parties for some of the infrastructure used to host data that is submitted to Platform, including cloud providers.
            </Text>
            <Text>
              Service Processors: We use third party service providers to process your personal data to assist us in business and technical operations. VTS has data processing agreements with such service providers, and their use of and access to personal data is limited to specific purposes. They provide services relating to: billing, customer support, internet and connectivity, marketing (direct mail, email, lead generation), security, user experience.
            </Text>
            <Text>
              Subcontractors/Independent Contractors: We may employ the assistance of independent contractors to work on specific projects. We train these independent contractors on applicable VTS policies and they are required to adhere to substantially the same data security practices as are VTS employees.
            </Text>
            <Text>
              As Required by Law: We release information about you if we believe we must do so to comply with the law or a subpoena, bankruptcy proceeding, or similar legal process.
            </Text>
            <Text>
              To Protect Rights: We may disclose information about you, such as your name, contact information, and billing information, to enforce our agreements with you or to protect the rights and safety of VTS, our customers, our users, and the general public, or as evidence in litigation in which we are involved
            </Text>
            <Text>
              In a Business Transaction: If VTS is involved in a merger, acquisition, or sale of all or a portion of its assets, your information may be transferred to the acquiring entity as part of the transaction, and may also be reviewed as part of the due diligence review for the transaction. For example, we may need to provide a list of all customer accounts and payment histories.
            </Text>
            <Text>
              Aggregate and Anonymized Information: We may share aggregate or anonymized information about users with third parties for marketing, advertising, research, or similar purposes. For example, if we display advertisements on behalf of a third party, we may share aggregate demographic information with that third party about the users to whom we displayed the advertisements.
            </Text>



            <TextHeading>
              6. Use of Cookies, Tracking Technologies, Analytics and Behavioral Marketing
            </TextHeading>
            <Text>
              In order to make our platform as user-friendly as possible, we – like many other companies – use “cookies”.
            </Text>
            <Text>
              Cookies: A cookie is a small text file that is stored in your web browser that allows VTS or a third party to recognize you. Cookies can either be session cookies or persistent cookies. A session cookie expires automatically when you close your browser. A persistent cookie will remain until it expires or you delete your cookies. Expiration dates are set in the cookies themselves; some may expire after a few minutes while others may expire after multiple years. Cookies placed by the website you’re visiting are sometimes called “first party cookies,” while cookies placed by other companies are sometimes called “third party cookies.”
            </Text>
            <Text>
              <i>Types of Cookies:</i>
            </Text>
            <Text>
              Essential Cookies: These are first party cookies that are sometimes called “strictly necessary” as without them we cannot provide much of the functionality that you need on the Services. For example, essential cookies help remember your preferences as you move around the Services.
            </Text>
            <Text>
              Analytics Cookies: These cookies track information about how the Services are being used so that we can make improvements and report our performance. They collect information about how visitors use the Services, which site the user came from, the number of each user’s visits, and how long a user stays on the Services. We might also use analytics cookies to test new pages or features to see how users react to them. Analytics cookies may either be first party cookies or third party cookies.
            </Text>
            <Text>
              Preference Cookies: These cookies are also sometimes called “functionality cookies.” During your visit to the Services, cookies are used to remember information you have entered or choices you make (such as your username, language, or region) on the Services. They also store your preferences when using the Services, for example, your preferred language. These preferences are remembered, through the use of persistent cookies, and the next time you visit the Services you will not have to set them again.
            </Text>
            <Text>
              Targeting or Advertising Cookies: These third party cookies are placed by third party advertising platforms or networks in order to deliver ads, track ad performance, and enable advertising networks to deliver ads that may be relevant to you based upon your activities (this is sometimes called “behavioral” “tracking” or “targeted” advertising). More information about how cookies are used for advertising purposes is explained below in Behavioral Targeting and Remarketing.
            </Text>
            <Text>
              VTS’s Use of Cookies: We may use cookies to uniquely identify you, to help us process your transactions and requests, to track aggregate and statistical information about user activity, and to display advertising on third-party sites. Generally, we use these technologies to manage content, analyze trends, monitor page visits and content downloads, administer the Platform, track users’ movements around the Platform, and to gather statistics about our user base as a whole. These technologies may provide us with personal data, information about devices and networks you utilize to access our Services, and other information regarding your interactions with our Services.
            </Text>
            <Text>
              How to Disable Cookies: The “help” portion of the toolbar on most browsers will tell you how to prevent your computer from accepting new cookies, how to have the browser notify you when you receive a new cookie, or how to disable cookies altogether. If you disable cookies, be aware that some features of our Services may not function.
            </Text>
            <TextHeading>
              <i>To learn more about how to control cookie settings through your browser:</i>
            </TextHeading>
            <Text>
              Click <a href="https://support.mozilla.org/en-US/kb/websites-say-cookies-are-blocked-unblock-them#w_check-cookie-settings">here</a> to learn more about the “Private Browsing” setting and managing cookie settings in Firefox.
            </Text>
            <Text>
              Click <a href="https://support.google.com/chrome/answer/95647?hl=en-GB&co=GENIE.Platform%3DDesktop">here</a> to learn more about “Incognito” and managing cookie settings in Chrome.
            </Text>
            <Text>
              Click <a href="https://support.microsoft.com/en-au/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d">here</a> to learn more about “InPrivate” and managing cookie settings in Internet Explorer.
            </Text>
            <Text>
              Click <a href="https://support.apple.com/en-om/guide/safari/ibrw1069/mac">here</a> to learn more about “Private Browsing” and managing cookie settings in Safari.
            </Text>
            <Text>
              <br></br>
            </Text>
            <Text>
              Clear GIFs. Clear GIFs (a.k.a. web beacons or pixel tags) are tiny graphics with a unique identifier, similar in function to cookies. In contrast to cookies, clear GIFs are embedded invisibly on web pages, and are not stored on your hard drive. We might use clear GIFs to track the activities of Platform visitors and users of our Services, to help us manage content, and to compile statistics about usage. We and our third party service providers also might use clear GIFs in HTML e-mails to our customers to help us track e-mail response rates, identify when our e-mails are viewed, and track whether our e-mails are forwarded.
            </Text>
            <Text>
              Third-Party Analytics. We also use automated devices and applications, such as Google Analytics (more info here) and Optimizely (more info here) to evaluate use of our Services. We use these tools to gather non-personal data about users to help us improve our Services and user experiences. These analytics providers may use cookies and other technologies to perform their services, and may combine the information they collect about you on our Platform with other information they have collected for their own purposes. This Policy does not cover such uses of data by third parties.
            </Text>
            <Text>
              Do Not Track. Some browsers have incorporated "Do Not Track" (DNT) features that can send a signal to the websites you visit indicating you do not wish to be tracked. Currently, our systems do not recognize browser “do-not-track” requests. In the meantime, you can use the “help” portion of the toolbar on most browsers to learn how to prevent your computer from accepting new cookies, how to have the browser notify you when you receive a new cookie, or how to disable cookies altogether. If you disable cookies, be aware that some features of our Platform/Services may not function.
            </Text>




            <TextHeading>
              7. Your Choices and Rights
            </TextHeading>
            <Text>
              Closing Your Account. If you wish to close your account, you may do so by logging in and using the Account Administration settings or by contacting us at contact.verifavia@normecgroup.com. If you shared any Content or information through our Services with other users, such Content or information will continue to be accessible to such users.
            </Text>
            <Text>
              Content. Requests to access, delete, or modify Content will be directed to the Customer who owns the account. If you wish to request access to personal data contained in Content to delete, modify, or limit use, please provide us with the name of the Customer who submitted your information to our Services. We will refer your request to that Customer and will support them as needed.
            </Text>
            <Text>
              Access and Correction. If you are not on an enterprise account, you may log in and use the Account Administration settings or contact us at contact.verifavia@normecgroup.com to access or update account profile information. If you are on an Enterprise account, you may login and use the Account Administration settings or contact an administrator for the account to access or update account profile information. VTS will support its enterprise customers as needed to update your account information.
            </Text>
            <Text>
              Marketing Choices. Customers can always opt out of being contacted by us for marketing or promotional purposes by following the opt-out instructions located in the e-mails we send, by changing the account privacy settings, or by emailing us at contact.verifavia@normecgroup.com. Please note that if you opt out of marketing communications, VTS will continue to send you transactional or service-related communications, such as service announcements and administrative messages. If you do not wish to receive these, you have the option to cancel your account by emailing us at contact.verifavia@normecgroup.com.
            </Text>
            <Text>
              Users in the European Economic Area.
            </Text>
            <Text>
              Individuals in the EEA have the following rights with respect to their personal data under the Data Protection Act 1998 or GDPR:
            </Text>
            <Text>
              <b>Access:</b> You can ask us to confirm whether we are processing your personal data; give you a copy of that data; and provide you with other information about your personal data such as what data we have, what we use it for, who we disclose it to, whether we transfer it abroad, how we protect it, how long we keep it for, what rights you have, how you can make a complaint, where we got your data from and whether we have carried out any profiling, to the extent that such information has not already been provided to you in this Policy.
            </Text>
            <Text>
              <b>Rectification:</b> You can ask us to rectify inaccurate information. We may seek to verify the accuracy of the data before rectifying it.
            </Text>
            <Text>
              <b>Erasure:</b> You can ask us to erase your personal data, but only where it is no longer needed for the purposes for which it was collected; you have withdrawn your consent (where the data processing was based on consent); following a successful right to object (see 'Objection' below); it has been processed unlawfully; or to comply with a legal obligation to which we are subject. We are not required to comply with your request to erase your personal data if the processing of your personal data is necessary for compliance with a legal obligation or for the establishment, exercise, or defense of legal claims. There are certain other circumstances in which we are not required to comply with your erasure request, although these two are the most likely circumstances in which we would deny that request.
            </Text>
            <Text>
              <b>Restriction:</b> You can ask us to restrict (i.e., keep but not use) your personal data, but only where its accuracy is contested (see 'Rectification' above), to allow us to verify its accuracy; the processing is unlawful, but you do not want it erased; it is no longer needed for the purposes for which it was collected, but we still need it to establish, exercise, or defend legal claims; you have exercised the right to object, and verification of overriding grounds is pending. We can continue to use your personal data following a request for restriction where we have your consent; to establish, exercise, or defend legal claims; or to protect the rights of another natural or legal person.
            </Text>
            <Text>
              <b>Objection:</b> You can object to any processing of your personal data which has our 'legitimate interests' as its legal basis, if you believe your fundamental rights and freedoms outweigh our legitimate interests. Once you have objected, we have an opportunity to demonstrate that we have compelling legitimate interests which override your rights and freedoms. In addition, you can object to the processing of your personal data for direct marketing purposes, which includes profiling to the extent that it is related to such direct marketing without providing any reason. We will then cease the processing of your personal data for direct marketing purposes.
            </Text>
            <Text>
              <b>Portability:</b> You can ask us to provide your personal data to you in a structured, commonly used, machine-readable format, or you can ask to have it 'ported' directly to another Data Controller, but only where our processing is based on your consent and the processing is carried out by automated means.
            </Text>
            <Text>
              <b>Withdrawal of Consent:</b> You can withdraw your consent in respect of any processing of personal data which is based upon a consent which you have previously provided.
            </Text>



            <TextHeading>
              8. Data Retention
            </TextHeading>
            <Text>
              We keep your personal data for as long reasonable necessary for the purposes set out in Section 4 above. Except as noted below, we will retain your account profile data as necessary for our legitimate business purposes or to comply with our legal obligations (such as record keeping, accounting, fraud prevention and other business administrative purposes); generally, where we no longer have a legitimate business purpose to retain it, we will anonymize or delete such personal data within 180 days after the closing of your account. However, we will maintain your personal information longer where required for tax or accounting purposes, to ensure we would be able to defend or raise a claim, or where we have a specific need to retain, though we will generally not keep personal data for longer than seven years following the last date of communication with you. Legitimate business purposes that we may rely on to keep your personal data when you are not a customer include direct marketing (where you have not opted-out) for up to two years, facilitating the restoration or establishment of a user account in the future, maintaining VTS’s business intelligence systems for analytics and other internal purposes, etc. Where your information is no longer required, we will ensure it is disposed of in a secure manner.
            </Text>


            <TextHeading>
              9. How We Protect Your Information
            </TextHeading>
            <Text>
              We have implemented technical, physical, and administrative safeguards to protect your information. However, no company, including VTS, can guarantee the absolute security of Internet communications.
            </Text>
            <Text>
              In order to protect your information from loss, misuse or unauthorised access or disclosure, we have put in place suitable physical, electronic and managerial procedures to safeguard and secure the information we collect. These steps include the following:
            </Text>
            <Text>
              - Data minimisation
            </Text>
            <Text>
              - Password best practice
            </Text>
            <Text>
              - Security best practice concerning devices (PCs, laptops, mobile devices), online accounts, website hosting, physical access and storage
            </Text>
            <Text>
              -Staff training and accountability on data protection
            </Text>
            <Text>
              A copy of our internal Data Security Policy is available on request.
            </Text>
            <Text>
              If you have any questions about how we secure your information, please contact us at contact.verifavia@normecgroup.com.
            </Text>

            <TextHeading>
              10. Data Breaches
            </TextHeading>
            <Text>
              Our Data Security Policy includes a clear process for handling a personal data breach, should one occur. Where appropriate, VTS will promptly notify you of any unauthorized access to your personal information.
            </Text>



            <TextHeading>
              11. Blogs/Forums
            </TextHeading>
            <Text>
              Our Platform may contain publicly accessible blogs and community forums (including the Community Features). Be aware that any information you provide in these areas may be read, collected, and used by others who access them. To request removal of your information from our blog or community forum, contact us at contact.verifavia@normecgroup.com. In some cases, we may not be able to remove your information; if that happens, we will let you know why.
            </Text>


            <TextHeading>
              12. Linked Sites/Third Party Widgets
            </TextHeading>
            <Text>
              Links to Other Websites. Our Services include links to other websites with privacy practices that may differ from ours. Any information you submit to a website not belonging to VTS is governed by that site’s privacy statements, not this one. We encourage you to carefully read the privacy statement of any website you visit.
            </Text>


            <TextHeading>
              13. Children’s Personal Data
            </TextHeading>
            <Text>
              The Services are not directed toward children and we do not encourage children to participate in providing us with any personally identifiable information. We do not knowingly collect any personal data from children under the age of 18. We encourage parents and legal guardians to monitor their children’s Internet usage and to help enforce this Policy by instructing their children never to provide personal data through the Services. If you have reason to believe that a child under the age of 18, without a parent or guardian's consent has provided personal data to us through the Services, please contact us at contact.verifavia@normecgroup.com, and we will use commercially reasonable efforts to delete that information.
            </Text>


            <TextHeading>
              14. International Transfers
            </TextHeading>
            <Text>
              International Transfer of Data. We are based in India and the information we collect is governed by Indian laws. The information we collect may be transferred to, used from, and stored in India or other jurisdictions in which VTS, our affiliates, or service providers are located; these jurisdictions (including India) may not guarantee the same level of protection of personal data as the jurisdictions in which you reside. By using the Services, you acknowledge and agree to any such transfer of information outside of the jurisdiction in which you reside.
            </Text>
            <Text>
              Law Enforcement Requests. In certain situations, we may be required to disclose personal data in response to lawful requests by public authorities, including to meet national security or law enforcement requirements.
            </Text>
            <Text>
              Onward Transfers. Third parties who process personal data on our behalf must agree to use such personal data only for the purpose for which it is provided by us and they must contractually agree to provide adequate protections for personal data. VTS will continue to be liable for any onward transfers of personal data to such third parties. Where required by applicable data protection laws, we have ensured that such third parties sign standard contractual clauses as approved by the European Commission or other supervisory authority.
            </Text>



            <TextHeading>
              15. Changes to this Policy
            </TextHeading>
            <Text>
              We may update this Policy to reflect changes to our privacy practices. If you are a VTS customer and we make any material changes that affect the way we treat information that we have previously collected from you, we will notify you by email (sent to the e-mail address specified in your account) or by means of a notice through the Services prior to the change becoming effective. We encourage you to periodically review this Policy for the latest information on our privacy practices.
            </Text>



            <TextHeading>
              16. How to Contact Us/Dispute Resolution
            </TextHeading>
            <Text>
              If you have any questions or concerns regarding the way in which your personal data is being processed or you want to exercise your rights above, please reach out to VTS using the contact information below:
            </Text>
            <Text>
              VTS’s Legal Head, who serves as Company’s data protection contact, and can be contacted at contact.verifavia@normecgroup.com
            </Text>
            <Text>
              If you remain dissatisfied, you have the right to reach out directly to the Data Protection Authority in your jurisdiction. We do ask that you please attempt to resolve any issues with us first, although you have a right to contact the Data Protection Authority at any time.            </Text>
          </ContentContainer>
        </FormWrap>
      </Container>
    </>
  );
};

export default PrivacyPolicy;

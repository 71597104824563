export const homeObjTwo = {
    id: 'whyus',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine1: 'Why us?',
    headline1: 'What makes people place their trust in us?',
    description1: 'Normec Verifavia SAS can assess monitoring plans and verify emission reports according to the ISO 17029 standard and Regulation 757/2015 (EU MRV) and UK MRV for any ship anywhere in the world, regardless of country of ownership, flag state, or class.',
    description2: 'We provide an easy and reliable solution to make sure your emissions data is prepared, maintained, and submitted to authorities well before the deadline.',
    description3: 'Our dedication to staying current with regulations, along with IT systems certification expertise, makes us a trusted choice for data reporting and monitoring.',
    description4: 'It’s why we do what we do, and why we invite you to join us in the journey to a healthier and brighter future.',
    description5: 'We are a team comprising naval architects and marine engineers with prior procurement experience in shipping companies, showcasing the following numbers:',
    buttonLabel: 'Learn More',
    imgStart: false,
    img: require('../../images/svg_1.svg'),
    alt: 'Expert',
    dark: true,
    primary: true,
    darkText: true,
};
export const homeObjOne = {
    id: 'about',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine1: 'Verification Dashboard',
    headline1: 'The Verification Dashboard provides information on various aspects such as the verification status of emission reports, plan reviews status, an issue log for communication purposes, and more.',
    description1: 'Developed by a team of hazmat experts and marine engineers with shipping procurement experience.',
    description2: 'Highly user-friendly',
    description3: 'Comes with user guides and round-the-clock assistance',
    buttonLabel: 'Know More',
    imgStart: false,
    img: require('../../images/svg_1.svg'),
    alt: 'Expert',
    dark: true,
    primary: true,
    darkText: false,


    topLine2: 'CII Dashboard',
    headline2: 'The CII Dashboard includes features for both daily data monitoring and conducting simulations based on CII ratings and speed parameters. ',
    description4: 'The MD and Suppliers Declaration of Conformity can be generated effortlessly for multiple orders, ensuring compliance with regulations and guidelines.',
    description5: 'API integration with multiple procurement systems.',
    description6: 'Catalog Review for IHM Relevance.',

    topLine3: 'EU ETS Dashboard',
    headline3: 'The EU ETS Dashboard offers a range of functionalities, including voyage-based emission reports, insights into the EU MRV (Monitoring, Reporting, and Verification) system, an EU ETS calculator, and more.',
    description4: 'The MD and Suppliers Declaration of Conformity can be generated effortlessly for multiple orders, ensuring compliance with regulations and guidelines.',
    description5: 'API integration with multiple procurement systems.',
    description6: 'Catalog Review for IHM Relevance.',
};
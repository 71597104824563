import React from 'react';
import Video1 from '../../videos/video_verification.webm';
import Video2 from '../../videos/video_cii.webm';
import Video3 from '../../videos/video_euets.webm';
import { Button } from '../ButtonElements';
import { useState } from 'react';

import {
    InfoContainer,
    InfoWrapper,
    InfoRow,
    Column1,
    TextWrapper,
    TopLine,
    Heading,
    Subtitle,
    Column2,
    Column3,
    HeroBg,
    VideoBg,
    DescriptionWrapper,
    BtnWrap
}
    from './InfoElements'
const InfoSection = (
    { lightBg,
        id,
        primary,
        dark,
        dark2,
        imgStart,
        topLine1,
        topLine2,
        topLine3,
        lightText,
        headline1,
        headline2,
        headline3,
        darkText,
        description1,
        description2,
        description3,
        description4,
        description5,
        description6,
    }) => {
    const [showFullDescription1, setShowFullDescription1] = useState(false);
    const [showFullDescription2, setShowFullDescription2] = useState(false);

    const toggleDescription1 = () => {
        setShowFullDescription1(!showFullDescription1);
    };
    const toggleDescription2 = () => {
        setShowFullDescription2(!showFullDescription2);
    };
    return (
        <>
            <InfoContainer lightBg={lightBg} id={id}>
                <InfoWrapper>
                    <InfoRow imgStart={imgStart}>
                        <Column1>
                            <HeroBg>
                                <VideoBg autoPlay loop muted src={Video1} type='video/webm' />
                            </HeroBg>
                            <TextWrapper>
                                <TopLine>{topLine1}</TopLine>
                                <Heading lightText={lightText}>{headline1}</Heading>
                                <br></br>
                                <BtnWrap>
                                    <Button
                                     to="https://www.verifavia-shipping.com/shipping-carbon-emissions-verification/service-eu-mrv-emissions-report-verification-5.php" target="_blank"
                                        smooth={true}
                                        duration={500}
                                        spy={true}
                                        exact="true"
                                        offset={-80}
                                        primary={primary ? 1 : 0}
                                        dark={dark ? 1 : 0}
                                        dark2={dark2 ? 1 : 0}
                                    >
                                        Know More
                                    </Button>
                                </BtnWrap>
                            </TextWrapper>
                        </Column1>

                        <Column2>
                            <HeroBg>
                                <VideoBg autoPlay loop muted src={Video2} type='video/webm' />
                            </HeroBg>
                            <TextWrapper>
                                <TopLine>{topLine2}</TopLine>
                                <Heading lightText={lightText}>{headline2}</Heading>
                                <br></br> <br></br>
                                <BtnWrap>
                                    <Button
                                     to="https://www.verifavia-shipping.com/shipping-carbon-emissions-verification/shipping-mrv-regulation-carbon-intensity-indicator-cii-212.php" target="_blank"
                                        smooth={true}
                                        duration={500}
                                        spy={true}
                                        exact="true"
                                        offset={-80}
                                        primary={primary ? 1 : 0}
                                        dark={dark ? 1 : 0}
                                        dark2={dark2 ? 1 : 0}
                                    >
                                        Know More
                                    </Button>
                                </BtnWrap>
                            </TextWrapper>
                        </Column2>

                        <Column3>
                            <HeroBg>
                                <VideoBg autoPlay loop muted src={Video3} type='video/webm' />
                            </HeroBg>
                            <TextWrapper>
                                <TopLine>{topLine3}</TopLine>
                                <Heading lightText={lightText}>{headline3}</Heading>
                                <BtnWrap>
                                    <Button
                                     to="https://www.verifavia-shipping.com/shipping-carbon-emissions-verification/shipping-mrv-regulation-inclusion-of-shipping-in-the-eu-emissions-trading-scheme-eu-ets-275.php" target="_blank"
                                        smooth={true}
                                        duration={500}
                                        spy={true}
                                        exact="true"
                                        offset={-80}
                                        primary={primary ? 1 : 0}
                                        dark={dark ? 1 : 0}
                                        dark2={dark2 ? 1 : 0}
                                    >
                                        Know More
                                    </Button>
                                </BtnWrap>
                            </TextWrapper>
                        </Column3>
                    </InfoRow>
                </InfoWrapper>
            </InfoContainer>
        </>
    )
}

export default InfoSection
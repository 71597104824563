import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  min-height: 100vh;
  position: relative;
  overflow: auto;
  background: #115757;
  padding: 20px;
`;

export const FormWrap = styled.div`
padding-left: 5rem;
padding-right: 5rem;
`;

export const Icon = styled(Link)`
  position: absolute;
  top: 60px;
  left: 60px;
  text-decoration: none;
  color: #fff;
  font-weight: 700;
  font-size: 32px;

  @media screen and (max-width: 480px) {
    top: 8px;
    left: 16px;
  }
`;

export const ContentContainer = styled.div`
 padding: 4rem;
`;
export const FormH1 = styled.h1`
  margin-bottom: 24px;
  color: #fff;
  font-size: 1.8rem;
  text-align: left;

`;

export const Text = styled.p`
  text-align: left;
  margin-top: 10px;
  color: #dfdfdf;
  font-size: 1rem;
  line-height: 1.6rem;
  text-align: justify;

  a {
    color: #fff; /* You can change the color to your preferred link color */
    text-decoration: underline;
    cursor: pointer;
  }

  @media screen and (max-width: 768px) {
    padding: 0;
  }
`;
export const TextHeading = styled.p`
  text-align: left;
  margin-top: 30px;
  color: #fff;
  font-size: 1.2rem;
  font-weight: bold;
  @media screen and (max-width: 768px) {
    padding: 0;
  }
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
`;

export const TableHeader = styled.th`
  background-color: #115757;
  color: #fff;
  padding: 12px;
  text-align: left;
  border: 1px solid #fff;
`;

export const TableData = styled.td`
  padding: 12px;
  text-align: left;
  border: 1px solid #fff;
  background-color: #146363;
  color: #fff;
`;
import React from 'react'
import {
    FooterContainer,
    FooterWrap,
    FooterLinksContainer,
    FooterLinksWrapper,
    FooterLinkItems,
    CountryContainer,
    FlagIcon,
    FooterLinkTitle,
    FooterLink,
    AddressLine,
    SocialMedia,
    SocialMediaWrap,
    SocialLogo,
    VerifaviaCheckLogo,
    WebsiteRights,
    SocialIcons,
    SocialIconLink1,
    SocialIconLink2,
    SocialIconLink3
} from './FooterElements'
import { FaFacebook } from 'react-icons/fa'
import { FaXTwitter } from 'react-icons/fa6'
import { FaLinkedin } from 'react-icons/fa';
import { animateScroll as scroll } from 'react-scroll'
import IndianFlag from '../../images/flag-1.png';
import FranceFlag from '../../images/flag-2.png';
import SingaporeFlag from '../../images/flag-3.png';
import verifavia_white_logo from '../../images/Logo_white.png';
import RequestDemo from '../RequestDemo';
const Foorter = () => {

    const toggleHome = () => {
        scroll.scrollToTop();
    }
    return (
        <FooterContainer id="contactus">
            <FooterWrap>
                <FooterLinksContainer>
                    <FooterLinksWrapper>

                        <FooterLinkItems>
                            <FooterLinkTitle> About Us </FooterLinkTitle>
                            <FooterLink to="https://normecverifavia.com/services/shipping-emissions-performance/"  target="_blank"> About Normec Verifavia Shipping </FooterLink>
                            <FooterLink to="https://normecverifavia.com/services/aviation/"  target="_blank"> About Normec Verifavia Aviation & SAF </FooterLink>
                            <FooterLink to="https://normecverifavia.com/services/shipping-material-testing/"  target="_blank"> About Shipping Material & Testing </FooterLink>
                            <FooterLink to="https://normecverifavia.com/services/sustainability/"  target="_blank"> About Sustainability </FooterLink>
                            <FooterLink to="/privacy_policy" target="_blank" rel="noopener noreferrer"> Our Privacy Policy</FooterLink>
                        </FooterLinkItems>
                        <FooterLinkItems>
                            <CountryContainer>
                                <FlagIcon src={IndianFlag} />
                                <FooterLinkTitle> India </FooterLinkTitle>
                            </CountryContainer>
                            <AddressLine> <b>Normec Verifavia-IHM Technical Services Pvt. Ltd. </b></AddressLine>
                            <AddressLine> Office No 4, Third Floor, Tower C, </AddressLine>
                            <AddressLine> DLF Building, IT Park, </AddressLine>
                            <AddressLine> 160101 Chandigarh, India </AddressLine>
                            <AddressLine>  </AddressLine>
                            <AddressLine>  </AddressLine>
                            <AddressLine>  +91 798 656 4736 </AddressLine>
                        </FooterLinkItems>
                    </FooterLinksWrapper>
                    <FooterLinksWrapper>
                        <FooterLinkItems>
                            <CountryContainer>
                                <FlagIcon src={FranceFlag} />
                                <FooterLinkTitle> France </FooterLinkTitle>
                            </CountryContainer>
                            <AddressLine> <b>Normec Verifavia SAS.</b> </AddressLine>
                            <AddressLine> Tour Egée, </AddressLine>
                            <AddressLine> 9-11 Allée de L’Arche, </AddressLine>
                            <AddressLine> 92400 Courbevoie, Paris (France) </AddressLine>
                            <AddressLine>  </AddressLine>
                            <AddressLine>  </AddressLine>
                            <AddressLine> +33 143 227 194, +33 665 697 489 </AddressLine>
                        </FooterLinkItems>
                        <FooterLinkItems>
                            <CountryContainer>
                                <FlagIcon src={SingaporeFlag} />
                                <FooterLinkTitle> Singapore </FooterLinkTitle>
                            </CountryContainer>
                            <AddressLine> <b>Normec Verifavia Shipping-IHM (Singapore)</b> </AddressLine>
                            <AddressLine> 1 North Bridge Road, </AddressLine>
                            <AddressLine> # 30-00 High Street Centre, </AddressLine>
                            <AddressLine> Singapore 179094 </AddressLine>
                            <AddressLine>  </AddressLine>
                            <AddressLine>  </AddressLine>
                            <AddressLine> +65 3157 3454 </AddressLine>
                        </FooterLinkItems>
                    </FooterLinksWrapper>
                </FooterLinksContainer>

                <SocialMedia>
                    <SocialMediaWrap>
                            <SocialLogo to="/" onClick={toggleHome}>
                            <VerifaviaCheckLogo src={verifavia_white_logo} />
                            </SocialLogo>
                        <WebsiteRights>contact.verifavia@normecgroup.com </WebsiteRights>
                        <SocialIcons>
                             <SocialIconLink1 href="https://x.com/NormecVerifavia" target="_blank" arial_label="X">
                                <FaXTwitter />
                            </SocialIconLink1>
                            {/*<SocialIconLink2 href="https://www.facebook.com/verifaviashipping/" target="_blank" arial_label="Facebook">
                                <FaFacebook />
                            </SocialIconLink2> */}
                            <SocialIconLink3 href="https://linkedin.com/company/normec-verifavia/" target="_blank" arial_label="Linkedin">
                                <FaLinkedin />
                            </SocialIconLink3>
                        </SocialIcons>
                    </SocialMediaWrap>
                </SocialMedia>

            </FooterWrap>
        </FooterContainer >

    )
}

export default Foorter
import React from 'react'

const AnimationComponent = () => {
    return (
        <div class="main">
            <h1>Emissions Verification Dashboard</h1>
            <h3>
                <div class="roller">
                    <span id="rolltext">1000+ vessels verified under EU MRV<br />
                        400+ vessels verified under UK MRV<br />
                        850+ vessels verified under IMO DCS<br />
                        450+ vessels using emission dashboard<br />
                    </span>
                </div>
            </h3>
        </div>
    );
};

export default AnimationComponent